<template>
    <div class="v-activities-index index-view">
        <div class="view-content">
            <main-navigation />
            <column-list-index :items="items" order-by="false" />
        </div>
    </div>
</template>

<script>
import MainNavigation from '@/components/MainNavigation.vue';
import ColumnListIndex from '@/components/ColumnListIndex.vue';

import { fetchActivitiesEntries } from '../http/fetchApi';

export default {
    name: 'ActivitiesIndex',
    components: {
        MainNavigation,
        ColumnListIndex
    },
    props: {
        slug: String,
    },
    data() {
        return {
            items: []
        }
    },
    mounted() {
        this.fetchEntries();
    },
    methods: {
        async fetchEntries() {
            const { data } = await fetchActivitiesEntries();
            this.items = data;

            // set header data
            this.$globalData.setHeaderData({
                title: 'F+F 1971 - Activities'
            });
        },
    }
}
</script>
